.main {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center;
  background-image: linear-gradient(
      135deg,
      rgba(12, 139, 140, 0.65) 0%,
      rgba(10, 41, 77, 0.65) 100%
    ),
    url("../public/assets/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.card {
  padding: 4rem 2rem !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0.5rem !important;
  background-color: white !important;
}

.header {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.header h1 {
  margin: 0;
}

.header img {
  width: 150px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.body input {
  width: 400px;
  line-height: 2.5rem;
  margin-top: 1rem;
  border: 2px solid rgb(211, 210, 210);
  border-radius: 0.5rem;
  text-align: center;
  font-weight: bold;
  font-size: medium;
}

.body input:focus {
  outline: none;
  border-color: rgb(211, 210, 210);
}

.body input:hover {
  background-color: rgb(248, 248, 248);
}

.body button {
  width: 400px;
  line-height: 2.5rem;
  border-radius: 0.5rem;
  text-align: center;
  font-weight: bold;
  font-size: medium;
  border: none;
  color: white;
  margin-top: 2rem;
  margin-bottom: 1rem;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.body button:hover {
  background-color: #1565c0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.body h5 {
  margin: 0;
  font-weight: normal;
}

.error {
  font-weight: bold;
  font-size: small;
  color: rgb(248, 61, 61);
}

.lucky {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-image: linear-gradient(
      135deg,
      rgba(0, 9, 17, 0.5) 0%,
      rgba(0, 9, 17, 0.5) 100%
    ),
    url("../public/assets/Background1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.winner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 0.5rem;
  border: 4px solid #1c2733;
  height: 98.5vh;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.winner h1 {
  margin: 0;
  font-size: 3rem;
  color: #e2e2e2;

  display: flex;
  flex-direction: row;
  align-items: self-end;
}

.spinner {
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
