.main {
  display: flex;
  flex-direction: row;
  background-color: #000911;
}

.winner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.winner-list-container {
  margin-top: 1.5rem;
  max-height: 81vh;
  overflow-y: auto;
}

.winner h1 {
  margin: 0;
  font-size: 3rem;
  color: #e2e2e2;
  display: flex;
  flex-direction: row;
  align-items: self-end;
}

.spinner {
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
