.card {
  position: relative;
  margin: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(12, 26, 37, 1) 0%,
    rgba(0, 9, 17, 1) 50%,
    rgba(12, 26, 37, 1) 100%
  );
  border-radius: 2rem;
  border: 3px solid #e2e2e2;
  color: #e2e2e2;
}

.card-details {
  width: 21rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-winner {
  font-size: x-large;
  font-weight: bolder;
  margin: 0;
}

.card-staff {
  font-size: large;
  font-weight: bolder;
  margin: 0;
}

.card-winner-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: smaller;
  font-weight: normal;
  margin: 0;
}

.card-other {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7rem;
}

.card-title {
  width: 14rem;
  font-weight: bold;
  font-size: medium;
  margin: 0;
}
