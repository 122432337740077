.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 1;
}

.withFireworks {
  position: relative;
}

.digits {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
  background: #0e171f;
  border-radius: 2rem;
  padding: 1rem 1rem;
  box-shadow: 0 1px 18px 25px rgba(0, 0, 0, 0.25);
  border: 4px solid #e2e2e2;
}

.digit {
  font-size: 8rem;
  background: linear-gradient(
    180deg,
    rgba(12, 26, 37, 1) 0%,
    rgba(0, 9, 17, 1) 50%,
    rgba(12, 26, 37, 1) 100%
  );
  color: #e2e2e2;
  margin-inline: 0.5rem;
  border-radius: 2rem;
  padding: 0 1.5rem;
  border: 3px solid #e2e2e2;
}

.digits.spinning {
  animation: 1s infinite linear;
}

.spinner button {
  background: #0e171f;
  color: #e2e2e2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  font-size: xx-large;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  margin-top: 3rem;
  padding: 1rem;
}

.spinner button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 3px solid #e2e2e2;
  border-right: 3px solid #e2e2e2;
  animation: border-top-right 3s infinite alternate;
}

.spinner button::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 3px solid #e2e2e2;
  border-left: 3px solid #e2e2e2;
  animation: border-bottom-left 3s infinite alternate;
}

@keyframes border-top-right {
  0% {
    width: 0px;
    height: 0px;
  }
  25% {
    width: 203px;
    height: 0px;
  }
  50% {
    width: 203px;
    height: 68px;
  }
  100% {
    width: 203px;
    height: 68px;
  }
}

@keyframes border-bottom-left {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  50% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  50.1% {
    width: 0px;
    height: 0px;
    opacity: 1;
  }
  75% {
    width: 203px;
    height: 0px;
    opacity: 1;
  }
  100% {
    width: 203px;
    height: 68px;
    opacity: 1;
  }
}
.winner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.winner h3 {
  position: relative;
  margin: 0;
  font-size: 3rem;
  font-weight: 900;
  color: #e2e2e2;
}

.winner h1 {
  position: relative;
  margin: 0;
  font-size: 4rem;
  font-weight: 900;
  margin-bottom: 1rem;
  color: rgb(250, 236, 217);
}

.winner h2 {
  position: relative;
  margin: 0;
  font-size: 5rem;
  font-weight: 900;
  color: #e2e2e2;
  z-index: 1;
  overflow: hidden;
}

.winner h2::before {
  content: "";
  position: absolute;
  left: -20%;
  width: 120%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, #000911 20%, #000911 100%);
  animation: animate 2s linear forwards;
  animation-iteration-count: infinite;
}

.winner img {
  width: 200px;
  border: 5px solid rgb(250, 236, 217);
  border-radius: 25px;
  padding: 0.25rem;
}

@keyframes animate {
  0% {
    left: -20;
  }
  100% {
    left: 110%;
  }
}
